<template>
  <div class="download">
    <div class="section section1">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <h1>下载ApiMini</h1>
          <p class="btns">
            <a>
              <img :src="require('@/assets/home/home_win.png')" alt="win" />
              <span>Windows</span>
            </a>
            <a>
              <img :src="require('@/assets/home/home_ios.png')" alt="ios" />
              <span>MacOS</span>
            </a>
          </p>
          <img :src="require('@/assets/home/sample.jpg')" class="sample" alt="img">
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'download',
  props: {},
};
</script>

<style lang="scss">
.download {
  .section {
    padding: 110px 0 150px;
    h1 {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 43px;
      color: #182634;
    }
    h2 {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 34px;
      color: #182634;
    }
    h3 {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 26px;
      color: #182634;
    }
    .sub-title {
      font-weight: 400;
      font-size: 21px;
      line-height: 28px;
      color: #182634;
    }
    .desc {
      font-size: 12px;
      line-height: 20px;
      color: #7e8791;
    }
    img.sample {
      width: 100%;
      height: auto;
    }
  }

  .section1 {
    text-align: center;
    .btns {
      margin: 30px 0 80px;
      a {
        margin: 0 20px;
        display: inline-block;
        width: 130px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        color: #4A525B;
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07);
        border-radius: 26px;
        img {
          margin-right: 5px;
        }
      }
    }
    
  }
}
</style>
